import vue from '@/main.js';
import {PTaskDetail} from '@/types';
class BeforeOpen{
  generateTaskDetailsSort(list){
    if(!list || list.length == 0){
      return [];
    }
    let sort = 1;
    const task_list = list.map(unit=>{
      unit.sort = sort++;
    })
    vue.$store.commit('set_task_config', Object.assign(
      {},
      vue.$store.state.task_config,
      {}
    ))
  }
  taskEditable(params){
    const {task, task_config} = vue.$store.state;
    const {users, tousers} = params || {};
    const userid = vue.$tools.getUserId();
    const {view, editable} = task_config;
    // 预览模式
    // if(view){
    //   return false;
    // }
    if(tousers){
      const tousers_arr = tousers.split(',')
      const is_in_users = tousers_arr.find(item=>{
        return item == userid
      })
      console.log(is_in_users)
      if(is_in_users === undefined){
        return false
      }
      return true
    }
    const is_in_users = users.find(item=>{
      return item.userId == userid;
    })
    if(is_in_users === undefined){
      return false;
    }

    return editable;
  }
  taskCreaterId(params){

    const {task, task_config} = vue.$store.state;
    const {userId} = task.task;
    return userId;
  }

  // 开启的时候设置配置
  async generateTaskConfig(){
    const {state, commit } = vue.$store;
    const {task_config, task} = state;
    const {call, recycle, share, select_target, use, task_type} = task_config;
    const route = vue.$route;
    const config = {};
    const {colour, hide} = task.task;
    // // console.log({task_config});
    // 设置颜色
    if(colour){
      config.color = colour;
    }
    if(hide){
      config.privacy = Boolean(hide);
    }
    if(call){
      // 清除颜色
      config.color = '';
      // 清除隐私
      config.privacy = false;
      // 设置调用目标群组
      if(route.name.indexOf('fun_task_dialog') != -1){
        if(route.params.id){
          config.chat_id = vue.$api.moduleTask.getRouteParamsId();
        }
        config.chat_type = vue.$tools.getChatType();
        if(vue.$store.state.dialog_data){
          config.from = vue.$store.state.dialog_data.chatName;
        }
        if(config.chat_type === 0){
          const user_data = vue.$store.state.user_data;
          config.from = user_data.friendRemark || user_data.userName;
        }
        config.select_target = select_target || false;
      }else{
        // 设置需要重新选择群组
        config.select_target = true;
      }
    }
    // 回收站 无法编辑
    if(recycle){
      config.editable = false;
    }
    // 分享 设置用户为创建者
    if(share){
      const res = await vue.$api.getUserData({userId: task.task.userId});
      config.users = [];
      if(res.message === 'success'){
        config.users.push(res.contents.user);
      }
    }
    // '我要使用'时如果是教学 则将其变更为实例
    if(use && task_type == 2){
      config.task_type = 3;
    }
    commit('set_task_config', Object.assign(
      {},
      task_config,
      config,
    ))
  }

  generateTaskDetails(){
    const {task, task_config} = vue.$store.state;
    const {call} = task_config;
    const {taskDeatails} = task;
    if(call){
      // 调用时删除提醒
      taskDeatails.forEach(item=>{
        item.remindList = [];
        // 只对智慧桌设置classes模式
        if(Number(item.type) === 11){
          item = new PTaskDetail(item);
        }
      })
    }
  }


}

const beforeOpen = new BeforeOpen();
export default beforeOpen;
