var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "v_fun_template_resource_center_file_thumbnail resource_group d-flex align-center cp",
      on: { click: _vm.viewFile },
    },
    [
      _c("div", { staticClass: "icon_group flex-shrink-0" }, [
        _c("div", {
          staticClass: "icon",
          class: {
            [_vm.fileData.fileType]: true,
          },
        }),
      ]),
      _c("div", { staticClass: "info_group" }, [
        _c("div", { staticClass: "name" }, [
          _vm._v("\n      " + _vm._s(_vm.fileName) + "\n    "),
        ]),
        _vm.userName
          ? _c(
              "div",
              {
                staticClass:
                  "detail_group d-flex align-center justify-space-between",
              },
              [
                _c("div", { staticClass: "detail" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.userName) +
                      " | " +
                      _vm._s(_vm.fileData.ownedEnterprises) +
                      "\n      "
                  ),
                ]),
                _c("div", { staticClass: "number_group d-flex align-center" }, [
                  _c("div", { staticClass: "icon" }),
                  _c("div", { staticClass: "number" }, [
                    _vm._v(_vm._s(_vm.browseCount)),
                  ]),
                ]),
              ]
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }