/*
 * @Author       : Hugo
 * @Date         : 2020-07-28 13:27:12
 * @LastEditors  : Hugo
 * @LastEditTime : 2022-01-04 15:48:21
 * @Description  :
 * @FilePath     : /miaohang/src/store/modules/template.js
 */

import vue from '@/main.js';
const template = {
  state: {
    template_my_list: null, // 模板列表(通用)
    template_my_tags: null, // 我的标签列表
    template_addtag_unit_data: null, // 添加标签模板数据
  },
  actions : {
    /**
     * 获取我的模板列表
     * @param {*} param0
     * @param {*} params
     */
    async get_template_my_list({state, commit}, params){

      let type = 'created';
      let searchtype = 1;
      let tagId = '';
      let keyword = '';
      let startId = '';
      let showflag = '';
      let tagType = params.tagType
      if(params){
        tagId = params.tagId || '';
        keyword = params.keyword || '';
        type = params.type;
        startId = params.startId || '';
        showflag = params.showflag || '';
      }
      if (type == "created") {
        // 我创建的
        searchtype = 1;
      } else if (type == "receive") {
        // 我接收的
        searchtype = 2;
      } else if (type == "purchase"){
        // 我购买的
        searchtype = 4;
      } else if (type == 'sell'){
        // 我销售的
        searchtype = 3;
      }
      if (tagType === 'teach_task') {
        searchtype = 3
      }
      if (params.searchtype) {
        searchtype = params.searchtype;
      }
      let data = {searchtype, tagId, keyword, startId, showflag};
      let res = await vue.$http.request('/projectTask/taskByparamList', data);
      // commit('set_template_my_list', res.contents.taskList);
      return res;
    },
    /**
     * §3.1.34 我的标签查询
     * @param {*} param0
     * @param {String} keywords | 关键词
     */
    async get_template_my_tags({commit}, keywords){
      // commit('set_template_my_tags', null);
      let res = await vue.$http.request('/projectTask/myTags', {keywords});
      let tagList = res.contents.tagList;
      tagList.forEach( item => {
        item.select = true;
      })
      commit('set_template_my_tags', tagList);
    }
  }
}
let mutations = {};
for(let i in template.state){
  mutations[`set_${i}`] = (state, payload) => {
    template.state[i] = payload;
  }
}
template.mutations = mutations;

export default template;
