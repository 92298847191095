import { listConvertToModel, updateModel } from '@/models'
import CourseSection from '@/models/cource_package/course_section'
import Userinfo from '@/types/modules/Userinfo'
import request from '@/api/request'
import post from '@/utils/axios/post'
import File from '@/models/resource/file'
class ResourceFile {
  browseCount
  collectCount
  content
  createTime
  file
  id
  likeCount
  ownedEnterprises
  taskId
  type
  userName
  likeFlag
  customFlag
  major
  teachType
  courselist

  fileType
  typeText
  fileName
  linkUrl
  // 转化过的浏览量
  convertBrowseCount
  convertCourseName
  convertTeachType


  constructor (params) {
    this.updateData(params)

  }

  generateCourseName () {
    const {
      courselist
    } = this
    if (courselist) {
      const {
        title
      } = courselist[0]
      this.convertCourseName = title
    }
  }

  generateTeachType () {
    const {
      teachType
    } = this
    if (teachType === 1) {
      this.convertTeachType = '标准课'
    } else if (teachType === 2) {
      this.convertTeachType = '实践课'
    } else if (teachType === 3) {
      this.convertTeachType = '专业群课'
    } else if (teachType === 4) {
      this.convertTeachType = '仿真'
    } else if (teachType === 5) {
      this.convertTeachType = '思政'
    } else if (teachType === 6) {
      this.convertTeachType = '双创融合'
    } else if (teachType === 7) {
      this.convertTeachType = '培训课'
    } else if (teachType === 8) {
      this.convertTeachType = '其他'
    } else {
      this.convertTeachType = '未知'
    }
  }

  generateFileType () {
    const {
      type,
      file,
    } = this

    let fileType;
    let textType
    if (type === 3) {
      fileType = 'link'
      textType = '网页链接'
    } else {
      if (file) {
        const { filename } = file

        // 设置图表类型
        const fileName = filename;
        const fileExtension = fileName.split('.').pop().toLowerCase();

        switch (fileExtension) {
          case 'zip':
          case 'rar':
            fileType = 'zip';
            textType = '压缩文件';
            break;
          case 'xls':
          case 'xlsx':
            fileType = 'excel';
            textType = 'Excel文件';
            break;
          case 'mp4':
          case 'avi':
          case 'mov':
          case 'wmv':
          case 'flv':
          case 'mkv':
            fileType = 'video'; // 这里只是列举了常见视频后缀，可按需扩充更多
            textType = '视频';
            break;
          case 'pdf':
            fileType = 'pdf';
            textType = 'PDF文件';
            break;
          case 'jpg':
          case 'jpeg':
          case 'png':
          case 'gif':
          case 'bmp':
            fileType = 'pic'; // 同样列举常见图片后缀，可完善
            textType = '图片';
            break;
          case 'ppt':
          case 'pptx':
            fileType = 'ppt';
            textType = 'PPT文件';
            break;
          case 'txt':
            fileType = 'txt';
            textType = '文本';
            break;
          case 'doc':
          case 'docx':
            fileType = 'word';
            textType = 'WORD文件';
            break;
          case 'glb':
            fileType = 'vr';
            textType = 'VR文件';
            break;
          case'mp3':
          case 'wav':
          case 'aac':
          case 'flac':
            fileType = 'audio'; // 常见音频后缀示例，可补充更多
            textType = '音频文件';
            break;
          case 'task':
            fileType = 'task';
            textType = '任务';
            break;
          case 'link':
            fileType = 'link';
            textType = '网页链接';
            break;
          default:
            fileType = 'unknown';
            textType = '未知类型';
        }
      }

    }


    // console.log(textType)
    this.fileType = fileType
    this.typeText = textType
  }

  generateFileName () {
    const {
      file,
      content,
      type
    } = this
    if (file) {
      this.fileName = file.filename
    }
    if (type === 3 && content) {
      const arr = content.split('|')
      this.fileName =  arr[0] || arr[1]
      this.linkUrl = arr[1]
    }
  }

  async getData () {
    const res = await request.request(
      '/resource/queryCource',
      {
        courceId: this.id
      }
    )
    if (res.message === 'success') {
      this.updateData(res.contents.PCource)
      return true
    }
    return false
  }

  updateData (params) {
    const {
      file,
    } = params ?? {}

    updateModel(params, this)

    if (file) {
      this.file = new File(file)
    }
    this.generateFileType()
    this.generateFileName()
    this.generateConvertBrowseCount()
    this.generateTeachType()
    this.generateCourseName()
  }

  async getDetail () {
    const res = await request.request(
      '/resource/teachFileDetail',
      {
        id: this.id
      }
    )
    if (res.message === 'success') {
      this.updateData(res.contents.PTeachFile)
      return true
    }
  }


   /**
   * 处理用户对资源文件的点赞操作
   * @returns {Promise} 请求结果的 Promise 对象
   */
  async doLike () {
    // 获取当前实例的 likeFlag 属性
    const {
      likeFlag
    } = this
    // 发送请求到 /resource/like 路径，请求方法为 POST
    const res = await request.request(
      '/resource/like',
      {
        // 资源文件的 ID
        id: this.id,
        // 点赞类型，2 表示素材点赞，1 表示课程点赞
        type: 2,
        // 操作类型，likeFlag 为真时为 0，表示取消点赞，否则为 1，表示点赞
        dotype: likeFlag ? 0 : 1,
      }
    )
    // 如果请求成功（res.message 为 'success'），则执行以下操作
    if (res.message === 'success') {
      // 如果 likeFlag 为真（即用户之前点过赞），则将 likeCount 减 1（如果 likeCount 存在，否则设置为 0）
      if (likeFlag) {
        this.likeCount = this.likeCount ? this.likeCount - 1 : 0
      }
      // 否则（即用户之前没有点过赞），则将 likeCount 加 1（如果 likeCount 存在，否则设置为 1）
      else {
        this.likeCount = this.likeCount ? this.likeCount + 1 : 1
      }
      // 更新当前实例的 likeFlag 属性，使其与操作后的状态相反（点赞变为未点赞，未点赞变为点赞）
      this.likeFlag = likeFlag ? 0 : 1
    }
    // 返回请求的响应结果
    return res
  }

  // 增加浏览量
  async doBrowse () {
    const res = await request.request(
      '/resource/teachFileBrowse',
      {
        id: this.id,
        type: 1, // 1 素材 2 任务
      }
    )
    if (res.message === 'success') {
      this.browseCount = this.browseCount ? this.browseCount + 1 : 1
      this.generateConvertBrowseCount()
    }
    return res
  }

  // 将浏览量转化为可读性更好的格式
  generateConvertBrowseCount () {
    const {
      browseCount
    } = this
    let convertBrowseCount = browseCount
    if (browseCount > 10000) {
      convertBrowseCount =  `${(browseCount / 10000).toFixed(1)}w`
    }
    this.convertBrowseCount = convertBrowseCount
  }


}

export default ResourceFile
