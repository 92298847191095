import { updateModel } from '@/models'
import request from '@/api/request'

class TaskFile {
  buy
  cancelflag
  chatId
  chatName
  chatType
  checkUserId
  checkUserName
  checkstatus
  collectionflag
  colour
  controls
  createTime
  createusername
  createuserthumbnail
  deleteDays
  deleteFlag
  draft
  endTime
  from
  fromchat
  hide
  id
  inFlag
  infolderflag
  introduction
  isremind
  level
  levelStatus
  locknum
  name
  packageId
  packageTitle
  preTaskId
  preuserid
  preusername
  price
  processStatus
  readNum
  readamount
  reason
  sale
  showTime
  showflag
  signUserId
  startTime
  tags
  taskDetail
  taskType
  taskchatnum
  tasks
  teachId
  teachType
  thumbnail
  tousers
  updateContent
  updateTime
  updateUserId
  updateusername
  updateuserthumbnail
  userId
  userName
  ownedEnterprises
  collectCount
  courselist
  customFlag
  likeFlag
  likeCount
  readamount


  fileType
  typeText
  fileName
  // 转化过的浏览量
  convertBrowseCount
  convertCourseName
  convertTeachType


  constructor (params) {
    this.fileType = 'task'
    this.typeText = '任务'
    this.convertTeachType = '实践任务'
    this.updateData(params)
  }

  updateData (params) {
    updateModel(params, this)
    this.generateCourseName()
  }

  generateCourseName () {
    const {
      courselist
    } = this
    if (courselist && courselist.length) {
      const {
        title
      } = courselist[0]
      this.convertCourseName = title
    }
  }

  async doLike () {
    // 获取当前实例的 likeFlag 属性
    const {
      likeFlag
    } = this
    // 发送请求到 /resource/like 路径，请求方法为 POST
    const res = await request.request(
      '/resource/like',
      {
        // 资源文件的 ID
        id: this.id,
        // 点赞类型，2 表示素材点赞，1 表示课程点赞, 3 任务点赞
        type: 3,
        // 操作类型，likeFlag 为真时为 0，表示取消点赞，否则为 1，表示点赞
        dotype: likeFlag ? 0 : 1,
      }
    )
    // 如果请求成功（res.message 为 'success'），则执行以下操作
    if (res.message === 'success') {
      // 如果 likeFlag 为真（即用户之前点过赞），则将 likeCount 减 1（如果 likeCount 存在，否则设置为 0）
      if (likeFlag) {
        this.likeCount = this.likeCount ? this.likeCount - 1 : 0
      }
      // 否则（即用户之前没有点过赞），则将 likeCount 加 1（如果 likeCount 存在，否则设置为 1）
      else {
        this.likeCount = this.likeCount ? this.likeCount + 1 : 1
      }
      // 更新当前实例的 likeFlag 属性，使其与操作后的状态相反（点赞变为未点赞，未点赞变为点赞）
      this.likeFlag = likeFlag ? 0 : 1
    }
    // 返回请求的响应结果
    return res
  }

  // 增加浏览量
  async doBrowse () {
    const res = await request.request(
      '/resource/teachFileBrowse',
      {
        id: this.id,
        type: 2, // 1 素材 2 任务
      }
    )
    if (res.message === 'success') {
      this.readamount = this.readamount ? this.readamount + 1 : 1
      this.generateConvertBrowseCount()
    }
    return res
  }


}

export default TaskFile
