<script>
export default {
  props: {
    fileData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    fileName () {
      const {
        fileData
      } = this
      const {
        name,
        fileName,
        fileType,
      } = fileData
      if (fileType === 'task') {
        return name
      }
      return fileName
    },
    userName () {
      const {
        fileData
      } = this
      const {
        userName,
        fileType,
        updateusername,
      } = fileData
      if (fileType === 'task') {
        return updateusername
      }
      return userName
    },
    browseCount () {
      const {
        fileData
      } = this
      const {
        browseCount,
        readamount,
      } = fileData
      return browseCount || readamount || 0
    }
  },
  methods: {
    viewFile () {
      this.$emit(
        'viewFile',
        this.fileData
      )
    }
  }
}

</script>

<template>
  <div
    class="v_fun_template_resource_center_file_thumbnail resource_group d-flex align-center cp"
    @click="viewFile"
  >
    <div
      class="icon_group flex-shrink-0"
    >
      <div
        class="icon"
        :class="{
          [fileData.fileType]: true
        }"
      ></div>
    </div>
    <div
      class="info_group"
    >
      <div
        class="name"
      >
        {{ fileName }}
      </div>
      <div
        v-if="userName"
        class="detail_group d-flex align-center justify-space-between"
      >
        <div
          class="detail"
        >
          {{ userName }} | {{ fileData.ownedEnterprises }}
        </div>
        <div
          class="number_group d-flex align-center"
        >
          <div
            class="icon"
          ></div>
          <div
            class="number"
          >{{ browseCount }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@mixin type($type) {
  &.#{$type} {
    background-image: url(~@/assets/images/resource/#{$type}.png);
  }
}
.v_fun_template_resource_center_file_thumbnail {
  width: 330px;
  height: 64px;
  padding: 0 16px;
  background: #FFFFFF;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.06);
  border-radius: 10px;
  border: 1px solid #E8EEFF;
  .icon_group {
    margin-right: 10px;
    .icon {
      @include background;
      @include type(zip);
      @include type(excel);
      @include type(video);
      @include type(pdf);
      @include type(pic);
      @include type(ppt);
      @include type(txt);
      @include type(unknown);
      @include type(word);
      @include type(vr);
      @include type(audio);
      @include type(task);
      @include type(link);
      width: 32px;
      height: 32px;
    }
  }
  .info_group {
    overflow: auto;
    width: 100%;
    .name {
      @include single_line_intercept;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      line-height: 20px;
      text-align: left;
      font-style: normal;
    }
    .detail_group {
      width: 100%;
      padding-top: 5px;
      overflow: auto;
      .detail {
        @include single_line_intercept;
        font-weight: 400;
        font-size: 12px;
        color: #9F9F9F;
        text-align: left;
        font-style: normal;
      }
      .number_group {
        padding-left: 10px;
        .icon {
          background-image: url(~@/assets/images/resource/center_of_resource_review.png);
          width: 20px;
          height: 14px;
        }
        .number {
          padding-left: 3px;
          font-size: 12px;
          color: #666666;
          text-align: left;
          font-style: normal;
        }
      }
    }
  }
}
</style>
